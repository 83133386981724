define(['app', '$console', 'componentHelper'], (app, $console, componentHelper) => {

  const foundationFinderProductMatcher = () => {
    const component = {};
    const BRAND = "brand";
    const PRODUCT = "product";
    const SHADE = "shade";
    const DATA_CHOICES = "data-choices";
    const DATA_MASTER_ID = "data-masterId";
    const DATA_SHOW = "data-show";
    const DATA_SHOW_FF_TOOL = "data-show-ff-tool";
    const trackingEventCategory = "Find Your Shade";

    component.config = {
      selectors: {
        dropdownInput: '.dropdownInput_input',
        simpleDropdownInput: '.simpleDropdownInput',
        matchButton: '.foundationFinderProductMatcher_matcher_cta',
        matcher: '.foundationFinderProductMatcher_matcher',
        brand: '.foundationFinderProductMatcher_matcher_dropdown.brand',
        product: '.foundationFinderProductMatcher_matcher_dropdown.product',
        shade: '.foundationFinderProductMatcher_matcher_dropdown.shade',
        resultContainer: '.foundationFinderProductMatcher_resultContainer',
        editResultsButton: '.foundationFinderProductMatcherResult_sourceProduct_cta',
        closeModalButton: '.modal_dialog_close',
        modalDialog: '.modal_dialog',
        dialogContent: '.modal_dialog_content.foundationFinderProductMatcherContainer',
        quickBuyButton: '.productQuickbuySimple',
        wishlistButton: '.productAddToWishlist_button_default',
        modalTriggerButton: '.foundationFinderProductMatcherTrigger',
        isTargetNoCloseButton: '.foundationFinderProductMatcherError_isTargetNotClose_cta',
        isTargetNoCloseEditProductButton: '.foundationFinderProductMatcherError_isTargetNotClose_editProduct_cta',
        invalidTargetOrSourceButton: '.foundationFinderProductMatcherError_isInvalidTargetOrSource_cta',
        fallbackErrorContainer: '.foundationFinderProductMatcherError_isInvalidTargetOrSource',
        matcherTitle: '.foundationFinderProductMatcher_matcher_title',
        resultsTitle: '.foundationFinderProductMatcherResult_title',
        errorTitle: '.foundationFinderProductMatcherError_title'
      },
      attributes: {
        productId: 'data-product-id'
      },
      channels: {
        inputSelected: 'dropdownInput/inputSelected',
        refreshChoices: 'dropdownInput/refreshChoices',
        reloadAccessibleModalHelper: 'modal/reloadAccessibleModalHelper'
      }
    };

    component.init = (element, userDriven) => {
      component.element = element;
      component.brand = component.element.querySelector(component.config.selectors.brand);
      component.product = component.element.querySelector(component.config.selectors.product);
      component.shade = component.element.querySelector(component.config.selectors.shade);
      component.brandSimpleDropdownInput = component.brand.querySelector(component.config.selectors.simpleDropdownInput);
      component.productSimpleDropdownInput = component.product.querySelector(component.config.selectors.simpleDropdownInput);
      component.shadeSimpleDropdownInput = component.shade.querySelector(component.config.selectors.simpleDropdownInput);
      component.brandDropdownInput = component.brand.querySelector(component.config.selectors.dropdownInput);
      component.productDropdownInput = component.product.querySelector(component.config.selectors.dropdownInput);
      component.shadeDropdownInput = component.shade.querySelector(component.config.selectors.dropdownInput);
      component.matchButton = component.element.querySelector(component.config.selectors.matchButton);
      component.matcher = component.element.querySelector(component.config.selectors.matcher);
      component.matcherTitle = component.element.querySelector(component.config.selectors.matcherTitle);
      component.resultContainer = component.element.querySelector(component.config.selectors.resultContainer);
      component.fallbackErrorContainer = component.element.querySelector(component.config.selectors.fallbackErrorContainer);
      component.modalDialog = document.querySelector(component.config.selectors.dialogContent);
      component.modal = component.modalDialog.parentElement;
      component.closeModalButton = component.modal.querySelector(component.config.selectors.closeModalButton);
      component.masterId = component.element.getAttribute(DATA_MASTER_ID);
      component.modalTriggerButton = document.querySelector(component.config.selectors.modalTriggerButton);
      component.modalTriggerButton.disabled = true;
      component.productDropdownInput.disabled = true;
      component.shadeDropdownInput.disabled = true;
      component.productMap = new Map();
      component.shadeMap = new Map();
      component.brandsList = [];
      component.showFFTool = component.element.getAttribute(DATA_SHOW_FF_TOOL) === 'true';

      if (!userDriven) {
        component.subscribe();
        component.getValues();
        component.bind();
      }
    };

    component.bind = () => {
      component.matchButton.addEventListener('click', component.getMatch);
      component.closeModalButton.addEventListener('click', component.refreshComponent);
      component.modalDialog.addEventListener('click', e => e.stopPropagation());
      component.modal.addEventListener('click', component.refreshComponent);
      component.modalTriggerButton.addEventListener('click', () => {
        app.publish('tracking/record', trackingEventCategory, 'Product page click', component.masterId);
      })
    };

    component.getMatch = () => {
      app.publish('tracking/record', trackingEventCategory, 'Meet your match', component.masterId);
      let url = `/match.foundationMatcher?source=${component.currentShadeSelected}&target=${component.masterId}`;

      if (component.showFFTool) {
        url += "&showFFToolProductMatcher=true";
      }

      app.ajax.get({
        url: url,
        success: component.getMatchSuccessHandler,
        error: component.getMatchErrorHandler
      });
    };

    component.refreshComponent = () => {
      component.showMatcher();

      component.resultContainer.innerHTML = '';
      component.brandDropdownInput.value = null;
      component.productDropdownInput.value = null;
      component.shadeDropdownInput.value = null;
      component.productDropdownInput.disabled = true;
      component.shadeDropdownInput.disabled = true;
      component.matchButton.disabled = true;

      component.updateDropdownData(
        component.brandSimpleDropdownInput,
        component.brandsList,
        BRAND);
      app.publish('tracking/record', trackingEventCategory, 'Modal close', component.masterId);
    };


    component.getMatchSuccessHandler = (response) => {
      component.resultContainer.innerHTML = response;
      component.editResultsButton = component.element.querySelector(component.config.selectors.editResultsButton);
      component.editResultsButton && component.editResultsButton.addEventListener('click', component.editResultsButtonHandler);
      component.quickBuyButton = component.element.querySelector(component.config.selectors.quickBuyButton);
      component.quickBuyButton && component.quickBuyButton.addEventListener('click', component.quickBuyButtonHandler);
      component.wishlistButton = component.element.querySelector(component.config.selectors.wishlistButton);
      component.wishlistButton && component.wishlistButton.addEventListener('click', () => {
        const productId = component.wishlistButton.getAttribute(component.config.attributes.productId);
        app.publish('tracking/record', trackingEventCategory, 'Modal add to wishlist', productId);
      });

      componentHelper.reloadAllComponents(component.resultContainer);

      component.hideMatcher();
      const resultsTitle = component.resultContainer.querySelector(component.config.selectors.resultsTitle);
      app.publish(component.config.channels.reloadAccessibleModalHelper, resultsTitle);

    };

    component.editResultsButtonHandler = () => {
      component.showMatcher();
      app.publish('tracking/record', trackingEventCategory, 'Modal edit', component.masterId);
    }

    component.getMatchErrorHandler = (response, status) => {
      if (response) {
        component.hideMatcher();

        if(status && status.includes('500')) {
          component.invalidTargetOrSourceButton = component.element.querySelector(component.config.selectors.invalidTargetOrSourceButton);
          component.invalidTargetOrSourceButton && component.invalidTargetOrSourceButton.addEventListener('click', component.showMatcher);
          component.fallbackErrorContainer.setAttribute(DATA_SHOW, "true");
          component.resultContainer.setAttribute(DATA_SHOW, "false");

        } else {
          component.resultContainer.innerHTML = response;
          component.isTargetNoCloseButton = component.resultContainer.querySelector(component.config.selectors.isTargetNoCloseButton);
          component.isTargetNoCloseButton && component.isTargetNoCloseButton.addEventListener('click', component.closeModal);
          component.invalidTargetOrSourceButton = component.resultContainer.querySelector(component.config.selectors.invalidTargetOrSourceButton);
          component.invalidTargetOrSourceButton && component.invalidTargetOrSourceButton.addEventListener('click', component.showMatcher);
          component.isTargetNoCloseEditProductButton = component.resultContainer.querySelector(component.config.selectors.isTargetNoCloseEditProductButton);
          component.isTargetNoCloseEditProductButton && component.isTargetNoCloseEditProductButton.addEventListener('click', component.refreshComponent);
        }

        component.matcher.setAttribute(DATA_SHOW, "false");
        const errorTitle = component.element.querySelector(component.config.selectors.errorTitle);
        app.publish(component.config.channels.reloadAccessibleModalHelper, errorTitle);

      } else {
        $console.error("ERROR: Match error object is null");
      }
    };

    component.quickBuyButtonHandler = () => {
      component.refreshComponent();
      component.closeModal();
      const productId = component.quickBuyButton && component.quickBuyButton.getAttribute(component.config.attributes.productId)
      app.publish('tracking/record', trackingEventCategory, 'Modal add to cart', productId);
    };

    component.getValues = () => {
      app.ajax.get({
        url: '/products.foundationMatcher',
        success: component.successHandler,
        error: component.errorHandler
      });
    };

    component.closeModal = () => {
      app.publish('modal/close');
      component.refreshComponent();
    };

    component.showMatcher = () => {
      component.resultContainer.setAttribute(DATA_SHOW, "false");
      component.matcher.setAttribute(DATA_SHOW, "true");
      component.fallbackErrorContainer.setAttribute(DATA_SHOW, "false");
      app.publish(component.config.channels.reloadAccessibleModalHelper, component.matcherTitle);
    };

    component.hideMatcher = () => {
      component.resultContainer.setAttribute(DATA_SHOW, "true");
      component.matcher.setAttribute(DATA_SHOW, "false");
      component.fallbackErrorContainer.setAttribute(DATA_SHOW, "false");
    };

    component.subscribe = () => {
      app.subscribe(component.config.channels.inputSelected, component.updateSelectedDropdown)
    };

    component.successHandler = (res) => {
      component.JSONdata = JSON.parse(res);

      for (let [brand, brandProducts] of Object.entries(component.JSONdata.brands)) {
        component.brandsList.push({value: brand, display: brand.trim()});
        let productList = [];

        brandProducts.forEach(product => {
          let shadeList = [];
          productList.push({value: product.productId, display: product.displayName.trim()});
          product.shades.forEach(shade =>
            shadeList.push({value: shade.productId, display: shade.displayName.trim()})
          );
          component.shadeMap.set(product.productId, shadeList)
        });
        component.productMap.set(brand, productList)
      }

      component.modalTriggerButton.disabled = false;

      component.updateDropdownData(
        component.brandSimpleDropdownInput,
        component.brandsList,
        BRAND);
    };

    component.errorHandler = () => {
      $console.error("Error: Failed the get brand products from foundation finder service");
    };

    component.updateSelectedDropdown = (selected) => {
      if(selected && selected.name === BRAND) {
        component.brandDropdownChange(selected.value);
      } else if (selected && selected.name === PRODUCT) {
        component.productDropdownChange(selected.value);
      } else if (selected && selected.name === SHADE) {
        component.shadeDropdownChange(selected.value);
      }
    };

    component.brandDropdownChange = (selectedValue) => {
      component.productDropdownInput.value = null;
      component.shadeDropdownInput.value = null;
      component.shadeDropdownInput.disabled = true;
      component.matchButton.disabled = true;

      if (!selectedValue) {
        component.productDropdownInput.disabled = true;
      } else {
        component.productDropdownInput.disabled = false;

        component.updateDropdownData(
          component.productSimpleDropdownInput,
          component.productMap.get(selectedValue),
          PRODUCT);
      }
    };

    component.productDropdownChange = (selectedValue) => {
      component.shadeDropdownInput.value = null;
      component.matchButton.disabled = true;

      if (!selectedValue) {
        component.shadeDropdownInput.disabled = true;
      } else {
        component.shadeDropdownInput.disabled = false;

        component.updateDropdownData(
          component.shadeSimpleDropdownInput,
          component.shadeMap.get(parseInt(selectedValue)),
          SHADE);
      }
    };

    component.shadeDropdownChange = (selectedValue) => {
      if (selectedValue) {
        component.matchButton.disabled = false;
        component.currentShadeSelected = selectedValue;
      } else {
        component.matchButton.disabled = true;
      }
    };

    component.updateDropdownData = (dropdown, data, inputName) => {
      if (data) {
        dropdown.setAttribute(DATA_CHOICES, JSON.stringify(data));
        app.publish(component.config.channels.refreshChoices, inputName);
      }
    };

    return component;
  };

  return foundationFinderProductMatcher;
});
